"use strict";
import moment from "moment";

angular.module("casist.ubytovanie").controller("UbytovanieDetailCtrl", [
  "$scope",
  "$modalInstance",
  "$controller",
  "Profile",
  "Ubytovanie",
  "SearchCtrl",
  "item",
  "items",
  "$timeout",
  "$modal",
  "$q",
  "DokladTypy",
  "Karta",
  "UbytovanieOsoba",
  "Izba",
  "Prevadzka",
  "Global",
  "Dialog",
  "Firma",
  "Notification",
  function (
    $scope,
    $modalInstance,
    $controller,
    Profile,
    Ubytovanie,
    SearchCtrl,
    item,
    items,
    $timeout,
    $modal,
    $q,
    DokladTypy,
    Karta,
    UbytovanieOsoba,
    Izba,
    Prevadzka,
    Global,
    Dialog,
    Firma,
    Notification
  ) {
    $scope.items = items;
    $scope.Karta = Karta;
    $scope.Izba = Izba;
    $scope.Firma = Firma;
    $scope.UbytovanieOsoba = UbytovanieOsoba;
    $scope.prevadzky = Prevadzka.restangularize(
      angular.copy(Profile.get("prevadzky"))
    );
    $scope.editingPrice = false;

    $scope.setPrevadzka = function (prevadzka) {
      $scope.record.prevadzka = prevadzka.id;
      $scope.currentPrevadzka = prevadzka;
      Profile.set("lastPrevadzka", prevadzka);
      delete $scope.record.izba;
      delete $scope.record.cena;
      delete $scope.record.dan_ubytovania;
      delete $scope.record.spolu;
    };
    $scope.getParovanie = function (query, pageQuery) {
      if (/^(VF|RP)[0-9]+$/.test(query)) {
        query = query.replace("VF", "").replace("RP", "");
      }
      return Ubytovanie.parovanie().getList(
        angular.extend(
          { firma: $scope.record.firma, search: query, include: true },
          pageQuery
        )
      );
    };

    var maxDate = function (date1, date2) {
      if (date1 > date2) {
        return date1;
      } else {
        return date2;
      }
    };
    var minDate = function (date1, date2) {
      if (date1 < date2) {
        return date1;
      } else {
        return date2;
      }
    };

    var getTaxOdchod = function () {
      var daysWithTax = $scope.daysWithTax;
      var maxDaysWithTax = Global.get('max_pocet_dni', 60);

      if ((daysWithTax + $scope.record.pocet <= maxDaysWithTax) || !daysWithTax) {
        return parseDate($scope.record.odchod);
      }

      var daysLeftWithTax = maxDaysWithTax - daysWithTax;
      if (daysLeftWithTax > 0) {
        return moment($scope.record.prichod).add(daysLeftWithTax, 'days').toDate();
      } else {
        return parseDate($scope.record.prichod);
      }
    };

    var countPrices = function () {
      if (!$scope.record.pocet || isNaN(parseFloat($scope.record.pocet))) {
        $scope.record.spolu = 0;
        $scope.record.dan_ubytovania = 0;
        return;
      }
      if ($scope.record.cena) {
        $scope.record.dan_ubytovania = 0;
        if (
          Global.get("ubytovanie_pocitat_dan") &&
          $scope.record.sluzba &&
          $scope.record.sluzba_data.typ_data.ubytovanie
        ) {
          var pocetDan;
          var prichod = parseDate($scope.record.prichod);
          var odchod = parseDate($scope.record.odchod);
          if (
            ($scope.record.pp_od || $scope.record.pp_do) &&
            ($scope.record.osoba_data.pp_prevadzka === $scope.currentPrevadzka.id || !$scope.record.osoba_data.pp_prevadzka)
          ) {
            var ppOd = parseDate($scope.record.pp_od || $scope.record.prichod);
            var ppDo = parseDate($scope.record.pp_do || $scope.record.odchod);
            pocetDan =
              Math.round((maxDate(ppOd, prichod) - prichod) / 3600000 / 24) +
              Math.round((odchod - minDate(odchod, maxDate(ppDo, prichod))) / 3600000 / 24);
          } else {
            pocetDan = Math.round((getTaxOdchod() - prichod) / 3600000 / 24);
          }
          $scope.record.dan_ubytovania = pocetDan > 0 ? pocetDan * ($scope.record.firma && $scope.record.znizena_dan ? znizenaDan : dan) : 0;
          $scope.record.dan_ubytovania = parseFloat($scope.record.dan_ubytovania.toFixed(12));
        }

        if ($scope.record.sluzba_data.use_cena_firma && $scope.record.firma_data && $scope.record.firma_data.cena_ubytovanie > 0) {
          $scope.record.cena = $scope.record.firma_data.cena_ubytovanie * (1 + Global.get("sd2") / 100);
          $scope.record.cena = $scope.record.cena.toFixed(4);
        }
        $scope.record.spolu = parseFloat($scope.record.pocet) * parseFloat($scope.record.cena) + $scope.record.dan_ubytovania;
        $scope.record.custom_price = false;
      }
    };

    var calcDaysWithTaxFromRecords = function (records) {
      records = _.filter(records, function (record) {
        return record.id !== $scope.record.id;
      });
      $scope.daysWithTax = _.reduce(records, function (sum, record) {
        return sum + record.pocet;
      }, 0);
    };

    $scope.countPrices = function () {
      if (!$scope.record.osoba_data || !$scope.record.osoba_data.rodne_cislo) {
        countPrices();
        return;
      }

      Ubytovanie.objects().getList({
        'osoba__rodne_cislo': $scope.record.osoba_data.rodne_cislo,
        'prichod__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
        'prichod__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31',
        'sluzba__use_cena_firma': true
      }).then(
        function (records) {
          calcDaysWithTaxFromRecords(records);
          countPrices();
        }
      ).catch(
        function (err) {
          console.log(err);
        }
      );
    };

    $scope.RecordCtrl = $controller("RecordCtrl", {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: Ubytovanie,
    });

    var defaultRecord = {
      prichod: getCurrentDate(),
    };

    $scope.RecordCtrl.main.init({
      endpoint: "ubytovanie",
      model: "record",
      master: item,
      list: items,
      defaultItem: defaultRecord,
      preSave: function (obj) {
        if (item.rezervacia && $scope.record.rezervacia) {
          if (window.confirm("Zrušenie rezervácie?")) {
            $scope.record.rezervacia = false;
          }
        }
        obj.dd = DokladTypy.UB.dd;
        obj.d_vyst = getCurrentDate();
        obj.d_zdp = obj.d_vyst;
        obj.cena = obj.cena || 0;
        obj.spolu = Math.round(parseFloat(obj.spolu) * 10000) / 10000.0;
        return obj;
      },
      postSave: function (obj) {
        Profile.parseObdobie(obj.prichod);
      },
      dataChanged: function () {
        $scope.currentPrevadzka = _.find($scope.prevadzky, {
          id: parseInt($scope.record.prevadzka),
        });
      },
      searchCtrl: SearchCtrl,
      $modalInstance: $modalInstance,
      focusField: "osoba",
    });
    if (!isEmpty(item)) {
      $scope.record = Ubytovanie.copy(item);
      _.defaults($scope.record, defaultRecord);
      if ($scope.record.prevadzka) {
        if ($scope.record.rezervacia && $scope.record.prevadzka_rezervacia) {
          $scope.currentPrevadzka = $scope.record.prevadzka_rezervacia;
          delete $scope.record.prevadzka_rezervacia;
        } else {
          $scope.currentPrevadzka = _.find($scope.prevadzky, {
            id: parseInt($scope.record.prevadzka),
          });
        }
      } else {
        $scope.setPrevadzka(
          _.find($scope.prevadzky, { id: Profile.get("lastPrevadzka").id }) ||
            $scope.prevadzky[0]
        );
      }
    } else {
      $scope.record = angular.copy(defaultRecord);
      $scope.setPrevadzka(
        _.find($scope.prevadzky, { id: Profile.get("lastPrevadzka").id }) ||
          $scope.prevadzky[0]
      );
    }
    defaultRecord.prevadzka = $scope.record.prevadzka;

    $scope.addIzba = function (izba) {
      return Izba.add(izba, { prevadzka: $scope.record.prevadzka });
    };
    $scope.getIzba = function (query) {
      return Izba.get(query, { prevadzka: $scope.record.prevadzka });
    };
    $scope.odchodChanged = function () {
      var prichod = parseDate($scope.record.prichod);
      var odchod = parseDate($scope.record.odchod);
      var maxDate = moment([prichod.getFullYear(), prichod.getMonth(), 1])
        .add(1, "M")
        .toDate();
      if (odchod > maxDate) {
        $scope.record.odchod = getCurrentDate(maxDate);
      }
      $scope.countPocet();
    };
    $scope.countPocet = function (resetOdchod) {
      if (resetOdchod && $scope.record.osoba) {
        var prichodDate = parseDate($scope.record.prichod);
        if (
          $scope.record.prichod &&
          parseDate($scope.record.osoba_data.dlhodobo_ubytovany_od) <=
            prichodDate
        ) {
          $scope.record.odchod = getCurrentDate(
            new Date(
              prichodDate.getFullYear(),
              prichodDate.getMonth(),
              daysInMonth(prichodDate) + 1
            )
          );
          $scope.record.dlhodobo = true;
        } else {
          $scope.record.dlhodobo = false;
        }
      }
      if (!$scope.record.prichod || !$scope.record.odchod) {
        return;
      }
      if ($scope.record.sluzba && $scope.record.sluzba_data.sumarna_cena) {
        $scope.record.pocet = 1;
      } else {
        $scope.record.pocet = moment(parseDate($scope.record.odchod)).diff(
          moment(parseDate($scope.record.prichod)),
          "days"
        );
      }
      updateIzbaSluzba();
      $scope.countPrices();
    };
    var dan = Global.get("dan_ubytovania", 0);
    var znizenaDan = Global.get("znizena_dan_ubytovania", 0);
    function daysInMonth(year, month) {
      if (angular.isDate(year)) {
        return new Date(year.getFullYear(), year.getMonth() + 1, 0).getDate();
      } else {
        return new Date(year, month, 0).getDate();
      }
    }
    var updateIzbaSluzba = function () {
      if (
        $scope.record.izba &&
        (!$scope.record.sluzba || $scope.record.sluzba_data.typ_data.ubytovanie)
      ) {
        var izba = $scope.record.izba_data;
        var sluzba, sluzba_data;
        if ($scope.record.dlhodobo) {
          sluzba = izba.sluzba_dlhodobe;
          sluzba_data = izba.sluzba_dlhodobe_data;
          $scope.record.cena = izba.cena_dlhodobe;
        } else {
          if (izba.sluzba_akcia) {
            sluzba = izba.sluzba_akcia;
            sluzba_data = izba.sluzba_akcia_data;
            $scope.record.cena = izba.cena_akcia;
          } else if ($scope.record.pocet >= izba.hranica) {
            sluzba = izba.sluzba_dlhodobe;
            sluzba_data = izba.sluzba_dlhodobe_data;
            $scope.record.cena = izba.cena_dlhodobe;
          } else {
            sluzba = izba.sluzba_kratkodobe;
            sluzba_data = izba.sluzba_kratkodobe_data;
            $scope.record.cena = izba.cena_kratkodobe;
          }
        }
        $scope.record.sluzba = sluzba;
        $scope.record.sluzba_data = sluzba_data;
        if ($scope.record.sluzba && $scope.record.sluzba_data.sumarna_cena) {
          $scope.record.cena = $scope.record.sluzba_data.modph;
        }
      }
    };

    $scope.menaShort = Global.get("mena_short");

    $scope.selectedIzba = function () {
      $timeout(function () {
        updateIzbaSluzba();
        $scope.selectedSluzba($scope.record.sluzba_data);
      });
    };
    $scope.selectedOsoba = function (osoba) {
      if (osoba.neubytovat) {
        Dialog.alert("Pozor! Túto osobu neubytovať!", "Pozor");
      }
      angular.extend(
        $scope.record,
        _.pick(
          osoba,
          "fakturacia",
          "firma",
          "firma_data",
          "pp_do",
          "pp_od",
          "cudzinec"
        )
      );
      if (osoba.firma) {
        $scope.record.znizena_dan = osoba.firma_data.znizena_dan;
      }
      var prichodDate = parseDate($scope.record.prichod);
      $timeout(function () {
        if (
          $scope.record.prichod &&
          parseDate(osoba.dlhodobo_ubytovany_od) <= prichodDate
        ) {
          $scope.record.odchod = getCurrentDate(
            new Date(
              prichodDate.getFullYear(),
              prichodDate.getMonth(),
              daysInMonth(prichodDate) + 1
            )
          );
          $scope.record.dlhodobo = true;
          $scope.countPocet();
        } else {
          $scope.record.dlhodobo = false;
        }
        if (
          !$scope.record.izba &&
          osoba.last_izba &&
          osoba.last_izba.prevadzka === $scope.currentPrevadzka.id
        ) {
          $scope.record.izba_data = osoba.last_izba;
          $scope.record.izba = osoba.last_izba.id;
          $scope.selectedIzba();
        } else {
          $scope.countPrices();
        }
      });
    };
    $scope.selectedSluzba = function (sluzba) {
      $timeout(function () {
        if (sluzba && !sluzba.typ_data.ubytovanie) {
          $scope.record.cena = sluzba.modph;
          if (!$scope.record.pocet) {
            $scope.record.pocet = 1;
          }
        }
        $scope.countPocet();
        $scope.countPrices();
      });
    };
    $scope.informAboutBiometricStatus = function (data) {
      const isBiometricPrevadzka = data.record.prevadzka_data.biometric_settings !== null;
      const isUbytovanieSluzba = data.record.sluzba_data.typ_data.ubytovanie;

      if (isBiometricPrevadzka && isUbytovanieSluzba) {
        Notification.add('Údaje o osobe boli aktualizované v systéme Biometric.', 'success', 10);
        if (data.record.osoba_data.is_active_in_biometric) {
          Notification.add('Osoba je aktivna v systéme Biometric.', 'success', 10);
        }
        if (!data.record.osoba_data.biometric_card_id) {
          Notification.add('Osoba nemá zadané č. karty do systému Biometric.', 'info', 10);
        }
      }
    };
    $scope.startPriceEditing = function () {
      $scope.editingPrice = !$scope.editingPrice;
      $timeout(function () {
        $("#custom_price").select();
      });
    };
    $scope.totalPriceChanged = function () {
      if ($scope.record.spolu < $scope.record.dan_ubytovania) {
        $scope.record.spolu = $scope.record.dan_ubytovania;
      }
      var ubytPrice = $scope.record.spolu - $scope.record.dan_ubytovania;
      $scope.record.cena =
        Math.round((ubytPrice / $scope.record.pocet) * 100) / 100.0;
      $scope.record.custom_price = true;
    };

    $scope.ok = function () {
      $timeout(function () {
        if (document.activeElement) {
          $(document.activeElement).trigger('blur');
        }
        var promise = $scope.RecordCtrl.main.save();
        if (promise) {
          promise.then(function (result) {
            $modalInstance.close(result);
            $scope.informAboutBiometricStatus(result);
          });
        }
      });
    };

    $scope.cancel = function () {
      $scope.RecordCtrl.main.dismiss();
    };
    $scope.deleteRecord = function () {
      $scope.RecordCtrl.main.remove().then(function () {
        $modalInstance.dismiss({ type: "removed", item: item });
      });
    };
  },
]);
