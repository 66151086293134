'use strict';

angular.module('casist.core')
  .factory('Outputs', ['Global', '$http', '$window', 'Auth', '$modal', function (Global, $http, $window, Auth, $modal) {
    var makeAuthenticatedRequest = function(params, requestSucceeded, requestFailed) {
      $.ajax(params).done(function(response) {
        requestSucceeded(response);
      }).fail(function(data) {
        if (data.status === 404) {
          requestFailed(data);
          return;
        }
        var response = JSON.parse(data.responseText).code;
        if (data.status == 403 && response == "token_not_valid") {
          var tokenConfirmed = Auth.renewTokenSync();
          if (tokenConfirmed) {
            params.headers['Authorization'] = $http.defaults.headers.common['Authorization'];
            $.ajax(params).done(function(response) {
              requestSucceeded(response);
            }).fail(function(error) {
              requestFailed(error);
            });
          } else {
            requestFailed(JSON.parse(data.responseText));
          }
        } else {
          requestFailed(JSON.parse(data.responseText));
        }
      });
    };
    return {
      openDownloadPDFUrl: function(endpoint, id, query) {
        var url = Global.get('serverAddress')+endpoint;
        if (id)
          url += "/"+id;
        url += "/print?download=1";

        var params = {
          url: url,
          async: false,
          type: 'POST',
          headers: {
            'Authorization': $http.defaults.headers.common['Authorization'],
            'X-Agenda': $http.defaults.headers.common['X-Agenda'],
          }
        };
        var requestSucceeded = function(response) {
          url += "&data="+response.downloadID;
          if (!isEmpty(query)) {
            angular.forEach(query, function(val, key) {
              if (val) {
                url += '&'+key+'='+val;
              }
            });
          }
          $window.open(url);
        };
        var requestFailed = function(error) {
          if (error.status === 403) {
            alert('Nemáte právo na tlač.')
          }
        };
        makeAuthenticatedRequest(params, requestSucceeded, requestFailed);
      },
      exportWithID: function (endpoint, id, query, printURL) {
        var url = Global.get('serverAddress')+endpoint;
        if (id)
          url += '/'+id;
        url += '/' + (printURL || 'print');

        var params = {
          url: url,
          async: false,
          type: 'POST',
          headers: {
            'Authorization': $http.defaults.headers.common['Authorization'],
            'X-Agenda': $http.defaults.headers.common['X-Agenda'],
          }
        };
        var result;

        var requestSucceeded = function(response) {
          url += "?data="+response.downloadID;
          if (!isEmpty(query)) {
            angular.forEach(query, function(val, key) {
              if (val) {
                url += '&'+key+'='+val;
              }
            });
          }
          $window.open(url);
          result = true;
        };
        var requestFailed = function(error) {
          if (error.status === 403) {
            alert('Nemáte právo na export.')
          } else if (error.status === 404) {
            alert('Požadovaná funkcia sa nenašla.');
          }
          result = false;
        };
        makeAuthenticatedRequest(params, requestSucceeded, requestFailed);

        return result;
      },
      openXLSExportUrl: function(endpoint, query) {
        var url = Global.get('serverAddress')+endpoint+'/export';

        var params = {
          url: url,
          async: false,
          type: 'POST',
          headers: {
            'Authorization': $http.defaults.headers.common['Authorization'],
            'X-Agenda': $http.defaults.headers.common['X-Agenda'],
          }
        };
        var requestSucceeded = function(response) {
          url += "?data="+response.downloadID;
          if (!isEmpty(query)) {
            angular.forEach(query, function(val, key) {
              if (val) {
                url += '&'+key+'='+val;
              }
            });
          }
          $window.open(url);
        };
        var requestFailed = function(error) {
          if (error.status === 403) {
            alert('Nemáte právo na export.')
          }
        };
        makeAuthenticatedRequest(params, requestSucceeded, requestFailed);
      },
      openTxtExportUrl: function(endpoint, query) {
        return this.openXLSExportUrl(endpoint, query);
      },
      openXMLExportUrl: function(endpoint, type, query) {
        var url = Global.get('serverAddress')+endpoint+'/export/'+type;

        var params = {
          url: url,
          async: false,
          type: 'POST',
          headers: {
            'Authorization': $http.defaults.headers.common['Authorization'],
            'X-Agenda': $http.defaults.headers.common['X-Agenda'],
          }
        };
        var requestSucceeded = function(response) {
          url += "?data="+response.downloadID;
          if (!isEmpty(query)) {
            angular.forEach(query, function(val, key) {
              if (val) {
                url += '&'+key+'='+val;
              }
            });
          }
          $window.open(url);
        };
        var requestFailed = function(error) {
          if (error.status === 403) {
            alert('Nemáte právo na export.')
          } else if (error.status === 404) {
            alert('Požadovaná funkcia sa nenašla.');
          }
        };
        makeAuthenticatedRequest(params, requestSucceeded, requestFailed);
      },
      openEdiExportUrl: function (endpoint, id, query, printURL) {
        return this.openPrintUrl(endpoint, id, query, printURL);
      },
      openExportUrlWithID: function (endpoint, id, query, printURL) {
        var url = Global.get('serverAddress')+endpoint;
        if (id)
          url += '/'+id;
        url += '/' + (printURL || 'print');

        var params = {
          url: url,
          async: false,
          type: 'POST',
          headers: {
            'Authorization': $http.defaults.headers.common['Authorization'],
            'X-Agenda': $http.defaults.headers.common['X-Agenda'],
          }
        };

        var requestSucceeded = function(response) {
          url += "?data="+response.downloadID;
          if (!isEmpty(query)) {
            angular.forEach(query, function(val, key) {
              if (val) {
                url += '&'+key+'='+val;
              }
            });
          }
          $window.open(url);
        };
        var requestFailed = function(error) {
          if (error.status === 403) {
            alert('Nemáte právo na export.')
          } else if (error.status === 404) {
            alert('Požadovaná funkcia sa nenašla.');
          }
        };
        makeAuthenticatedRequest(params, requestSucceeded, requestFailed);
      },
      openShipmentUrl: function(endpoint, data) {
        var url = Global.get('serverAddress') + endpoint;

        var params = {
          url: url,
          async: false,
          type: 'POST',
          data: JSON.stringify(data),
          headers: {
            'Authorization': $http.defaults.headers.common['Authorization'],
            'X-Agenda': $http.defaults.headers.common['X-Agenda'],
            'Content-Type': 'application/json'
          }
        };
        var requestSucceeded = function(response) {
          url += "?data=" + response.downloadID;
          $window.open(url);
        };
        var requestFailed = function(error, msg) {
          if (!angular.isDefined(msg)) {
            msg = 'Nemáte právo na tlač.';
          }
          if (error.status === 403) {
            alert(msg)
          }
        };
        makeAuthenticatedRequest(params, requestSucceeded, requestFailed);
      },
      openPrintUrl: function(endpoint, id, query, printURL) {
        var url = Global.get('serverAddress')+endpoint;
        if (id)
          url += '/'+id;
        url += '/' + (printURL || 'print');

        var params = {
          url: url,
          async: false,
          type: 'POST',
          headers: {
            'Authorization': $http.defaults.headers.common['Authorization'],
            'X-Agenda': $http.defaults.headers.common['X-Agenda'],
          }
        };
        var requestSucceeded = function(response) {
          url += "?data="+response.downloadID;
          if (!isEmpty(query)) {
            angular.forEach(query, function(val, key) {
              if (val) {
                url += '&'+key+'='+val;
              }
            });
          }
          $window.open(url);
        };
        var requestFailed = function(error, msg) {
          if (!angular.isDefined(msg)) {
            msg = 'Nemáte právo na tlač.';
          }
          if (error.status === 403) {
            alert(msg)
          }
        };
        makeAuthenticatedRequest(params, requestSucceeded, requestFailed);
      },
      sendMail: function(to, subject, message, object, title) {
        return $modal.open({
        template: require('../../core/mailbox/sender.html'),
        controller: 'MailSenderCtrl',
        backdrop: 'static',
        windowClass: 'detail-md',
        resolve: {
          to: function() {
            return to;
          },
          subject: function() {
            return subject;
          },
          message: function() {
            return message;
          },
          object: function() {
            return object;
          },
          title: function() {
            return title;
          }
        }
      }).result;
      }
    }
  }]);
